import profilePermisionService from '../components/permissions/services/profile-permision.service';

export class Role {
  public id: string;

  public name: string;

  public company_id: string | undefined;

  public system: boolean;

  public company_name: string;

  public has_relationship: boolean | number = false;

  public active: boolean | number;

  public permission: any[];

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name || '';
    this.system = data.system;
    this.active = data.active === false ? 0 : 1;
    this.permission = data.permission || [];
    this.company_id = data.company ? data.company.id : undefined;
    this.company_name = data.company ? data.company.name : undefined;
    this.has_relationship = data.has_relationship === true;
  }

  public get activeText() {
    return this.active ? 'Ativo' : 'Inativo';
  }

  delete() {
    return profilePermisionService.deleteRolePermissions(this);
  }
}
