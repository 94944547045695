
import { Component, Vue } from 'vue-property-decorator';
import rulesService from '@/services/rules.service';
import AlertError from '@/components/alert-error/alert-error.component.vue';
import DeleteDialog from '@/components/dialogs/delete-dialog.component.vue';
import ProfilePermissionList from './profile-permission-list.component.vue';
import Permission from './entities/permissions.entity';
import profilePermisionService from './services/profile-permision.service';
import userRolesService from '../../../Users/services/user-roles.service';
import { Role } from '../../entities/role.entity';
import { Company } from '../../../Companies/entities/company.entity';
import companiesService from '../../../Companies/services/companies.service';

@Component({ components: { ProfilePermissionList, AlertError, DeleteDialog } })
export default class ProfilePermissionsComponent extends Vue {
  public $refs: any;

  private permissionsAdded: string[] = [];

  private permissions: any[] = [];

  private errors: any[] = [];

  private showDialogDelete: boolean = false;

  private companies: Company[] = [];

  private role: Role = new Role();

  private status: any[] = [
    {
      id: 0,
      name: 'Inativo',
    },
    {
      id: 1,
      name: 'Ativo',
    },
  ];

  private rules: any = {
    required: rulesService.required,
  };

  private get hasError(): boolean {
    return Object.keys(this.errors).length > 0;
  }

  private get isEdit() {
    const { id } = this.$route.params;
    return !!id;
  }

  private get hasPermissionsAdded(): boolean {
    return this.permissionsAdded.length > 0;
  }

  private get buttonLabel(): string {
    return this.role.id ? 'Salvar alterações' : 'Salvar';
  }

  private addPermision(permission: Permission) {
    this.permissionsAdded.push(permission.id);
  }

  private removePermision(permission: Permission) {
    const index = this.permissionsAdded.indexOf(permission.id);
    this.permissionsAdded.splice(index, 1);
  }

  private formatPermissionsToArray(permissions: any) {
    return Object.keys(permissions).map(it => ({
      name: it,
      modulePermissions: permissions[it],
    }));
  }

  private setShowDialogDelete(role: Role) {
    this.showDialogDelete = true;
  }

  private closeDialogDelete() {
    this.showDialogDelete = false;
  }

  private deleteRolePermissions() {
    this.role
      .delete()
      .then(() => {
        this.errors = [];
        this.$snackbar.open({
          text: 'Permissão excluída com sucesso.',
          color: 'success',
          buttonColor: '#fff',
        });
        this.goToList();
      })
      .catch((err: any) => {
        this.errors = err.response.data.errors;
        this.$snackbar.open({
          text: 'Ocorreu um erro ao excluir.',
          color: 'danger',
          buttonColor: '#fff',
        });
        throw new Error(`DEFAULT ERROR${err}`);
      })
      .finally(() => {
        this.showDialogDelete = false;
      });
  }

  private checkAllPermissions(permission: Permission[]) {
    this.permissions = [];
    this.permissions = permission;
    this.permissionsAdded = this.getPermissionsAddedCheckAll(permission);
  }

  private getPermissionsAddedCheckAll(permissions: Permission[]) {
    return permissions.reduce((acc: string[], value: any) => {
      const moduleIds = value.modulePermissions
        .map((it: any) => (it.active ? it.id : undefined))
        .filter((item: string) => item);
      acc = [...acc, ...moduleIds];
      return acc;
    }, []);
  }

  private async submit() {
    if (!this.$refs.form.validate()) return;
    if (this.isEdit) {
      this.updateRolePermissions();
      return;
    }
    this.saveRolePermissions();
  }

  private goToList() {
    this.$router.push({
      name: 'profile-access-list',
    });
  }

  private updateRolePermissions() {
    const { id } = this.$route.params;
    this.role.permission = this.permissionsAdded;
    profilePermisionService
      .updateRolePermissions(id, this.role)
      .then(() => {
        this.errors = [];
        this.$snackbar.open({
          text: 'Permissões alteradas com sucesso.',
          color: 'success',
          buttonColor: '#fff',
        });
        this.goToList();
      })
      .catch((err: any) => {
        this.errors = err.response.data.errors;
        this.$snackbar.open({
          text: 'Ocorreu um erro ao salvar.',
          color: 'danger',
          buttonColor: '#fff',
        });
        throw new Error(`${err}`);
      });
  }

  private saveRolePermissions() {
    this.role.permission = this.permissionsAdded;
    profilePermisionService
      .saveRolePermissions(this.role)
      .then(() => {
        this.errors = [];
        this.$snackbar.open({
          text: 'Cadastro realizado com sucesso.',
          color: 'success',
          buttonColor: '#fff',
        });
        this.goToList();
      })
      .catch((err: any) => {
        this.errors = err.response.data.errors;
        this.$snackbar.open({
          text: 'Ocorreu um erro ao salvar.',
          color: 'danger',
          buttonColor: '#fff',
        });
        throw new Error(`${err}`);
      });
  }

  private async getPermissions() {
    const permissions = await profilePermisionService.getPermissions();
    this.permissions = this.formatPermissionsToArray(permissions);
  }

  private async getCompanies() {
    const companies = await companiesService.getAllCompanies();
    this.companies = companies.map((company: any) => new Company(company));
  }

  private async getRoleById() {
    const { id } = this.$route.params;
    if (!id) return;
    const role = await userRolesService.getRoleById(id);
    this.role = new Role(role);
    this.permissionsAdded = this.role.permission.map(it => it.id);
  }

  private created() {
    this.getPermissions();
    this.getRoleById();
    this.getCompanies();
  }
}
