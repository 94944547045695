import { AxiosResponse } from 'axios';
import http from '@/services/http.service';

class UserRolesService {
  public getRoles() {
    return http.get('/profile/role').then(({ data }: AxiosResponse) => data.data);
  }

  public getRoleById(id: string) {
    return http.get(`/profile/role/${id}`).then(({ data }: AxiosResponse) => data.data);
  }
}

export default new UserRolesService();
