import { AxiosResponse } from 'axios';
import {
  ISimplePaginationService,
  SimplePagination,
} from '@/entities/pagination/simple-pagination';
import http from '@/services/http.service';
import { Role } from '../../../entities/role.entity';

class ProfilePermissionService {
  public simplePaginationService: ISimplePaginationService;

  constructor(simplePaginationService: ISimplePaginationService) {
    this.simplePaginationService = simplePaginationService;
  }

  public getPermissions() {
    return http.get('/profile/permission').then(({ data }: AxiosResponse) => data.data);
  }

  public updateRolePermissions(roleId: string, role: Role) {
    return http.put(`/profile/role/${roleId}`, role).then(({ data }: AxiosResponse) => data.data);
  }

  public deleteRolePermissions(role: Role) {
    return http.delete(`/profile/role/${role.id}`).then(({ data }: AxiosResponse) => data.data);
  }

  public saveRolePermissions(role: Role) {
    return http.post('/profile/role', role).then(({ data }: AxiosResponse) => data.data);
  }

  public getRoles() {
    return http.get('/profile/role').then(({ data }: AxiosResponse) => data.data);
  }

  public getRolesPaginated() {
    return http
      .get('/profile/role', {
        params: {
          paginate: 1,
        },
      })
      .then(({ data }: AxiosResponse) => {
        this.simplePaginationService.setPaginationLinks(data.links);
        return data.data;
      });
  }

  public getRolesByCompanyId(companyId: string) {
    return http
      .get('/profile/role', {
        params: {
          company_id: companyId,
        },
      })
      .then(({ data }: AxiosResponse) => data.data);
  }
}

const simplePaginationService = new SimplePagination();
export default new ProfilePermissionService(simplePaginationService);
