
import { Component, Vue, Prop } from 'vue-property-decorator';
import Permission from './entities/permissions.entity';

@Component
export default class UserPermissionListComponent extends Vue {
  @Prop({ type: Array, default: () => [] })
  private readonly permissions!: any[];

  @Prop({ type: Array, default: () => [] })
  private permissionsAdded!: string[];

  @Prop({ type: Boolean, default: () => false })
  private disabled!: boolean;

  private headers: any[] = [
    {
      text: '',
      value: 'actions',
      sortable: false,
      width: '5%',
    },
    {
      text: 'Permissões de acesso',
      value: 'module',
      sortable: false,
      width: '40%',
    },
    {
      text: 'Consultar',
      value: 'read',
      sortable: false,
      width: '10%',
    },
    {
      text: 'Incluir',
      value: 'create',
      sortable: false,
      width: '10%',
    },
    {
      text: 'Alterar',
      value: 'update',
      sortable: false,
      width: '10%',
    },
    {
      text: 'Excluir',
      value: 'delete',
      sortable: false,
      width: '10%',
    },
  ];

  private get modulePermissionsList() {
    return this.permissions.reduce((acc, item) => {
      const permissionFormatted = item.modulePermissions.map((moduleItem: Permission) => ({
        ...moduleItem,
        active: moduleItem.active || this.permissionsAdded.some(it => it === moduleItem.id),
      }));

      acc.push({
        ...item,
        modulePermissions: permissionFormatted,
      });
      return acc;
    }, []);
  }

  private isModuleChecked(modulePermissions: any[]) {
    return modulePermissions.every((it: any) => it.active);
  }

  private isAllModuleChecked() {
    // eslint-disable-next-line max-len
    return this.modulePermissionsList.every((it: any) => it.modulePermissions.every((module: any) => module.active));
  }

  private handlePermissions(permission: Permission, event: boolean) {
    if (event) {
      this.$emit('add-permision', permission);
      return;
    }
    this.$emit('remove-permision', permission);
  }

  private handleActiveAllPermissions(permission: any, event: boolean) {
    permission.modulePermissions.forEach((it: any) => {
      if (event) {
        it.active = event;
      }

      this.handlePermissions(it, event);
      it.active = event;
    });
  }

  private handleActiveAllModules(event: boolean) {
    const permissions = this.permissions.map((permission: any) => {
      const modulePermissions = this.handleActiveModulePermission(
        permission.modulePermissions,
        event,
      );

      return {
        ...permission,
        modulePermissions,
      };
    });

    this.$emit('check-all-permissions', permissions);
  }

  private handleActiveModulePermission(it: any, event: boolean) {
    return it.map((item: any) => ({
      ...item,
      active: event,
    }));
  }
}
